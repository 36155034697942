/* src/App.css */
@import '~@fortawesome/fontawesome-free/css/all.min.css';

.App {
  text-align: center;
  background-color: #f5f5f5;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: 12px; /* 文字の大きさを小さく */
  color: #333;
  padding: 20px;
}

.App-header {
  background-color: #fff;
  padding: 20px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  width: 90%; /* 横長の画面に対応 */
}

h1 {
  color: #333;
  font-size: 1.5em; /* ヘッダーの文字サイズも調整 */
}

table {
  border-collapse: collapse;
  width: 100%; /* 横長の画面に対応 */
  margin: 20px 0;
  font-size: 0.9em; /* 文字の大きさを小さく */
  background-color: #fff;
  border-radius: 8px;
  overflow: hidden;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

table, th, td {
  border: 1px solid #ddd;
}

th, td {
  padding: 8px; /* パディングを調整して密度を高く */
  text-align: left;
}

th {
  background-color: #f0f0f0;
  color: #333;
}

tr:nth-child(even) {
  background-color: #f9f9f9;
}

tr:hover {
  background-color: #f1f1f1;
}

tr td {
  color: #333;
}

tr td.green {
  color: green;
}

tr td.blue {
  color: blue; /* 色を青に変更 */
}

tr td.red {
  color: red;
}

.fa-spin {
  margin-left: 8px;
  animation: fa-spin 1s infinite linear;
}

@keyframes fa-spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

/* レスポンシブデザイン */
@media (max-width: 768px) {
  .App-header {
    width: 100%;
  }

  table {
    font-size: 0.8em; /* さらに小さく */
  }

  th, td {
    padding: 6px; /* パディングを調整 */
  }
}

